<template>
  <ConfirmDialog
    maxWidth="1200px"
    positiveButtonText
    :value.sync="show"
    :title="title"
    :loading="loading || cardLoading"
    noPadding
    showCloseBtn
  >
    <v-layout column mb-2 slot="header" style="width: 100%">
      <v-layout mx-4 wrap align-center>
        <v-flex shrink mx-4>
          <RoundInitialsButton
            :size="60"
            :name="owner.name"
          ></RoundInitialsButton>
        </v-flex>
        <DefaultTitle>{{ owner.name }}</DefaultTitle>
        <v-layout>
          <v-spacer></v-spacer>
          <v-layout column align-end shrink mx-4>
            <DefaultLabel>
              Wizyty:
              <b>{{ internalOwner.appointments }}</b>
            </DefaultLabel>
            <DefaultLabel>
              Leczenia:
              <b>{{ internalOwner.sessions }}</b>
            </DefaultLabel>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-layout>
    <v-layout column slot="content">
      <v-layout column class="popup-content">
        <PatientCard
          ref="pcard"
          :isLoading.sync="cardLoading"
          :patient="patient"
          :owner="owner"
          :enableUpdateFieldButtons="
            internalOwner && internalOwner.has_unfinished_appointment
          "
          @success="handleSuccess"
          @error="handleError"
        ></PatientCard>
      </v-layout>
    </v-layout>
    <v-layout column slot="footer">
      <v-layout>
        <MoreCardActionButton
          :isLoading.sync="cardLoading"
          :patient="patient"
          :sessionGetter="getSession"
          :owner="owner"
          @success="handleSuccess"
          @error="handleError"
        ></MoreCardActionButton>
        <v-spacer></v-spacer>
        <DefaultButton class="ml-4 my-0" @click.prevent="show = false"
          >Zamknij</DefaultButton
        >
      </v-layout>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
import PatientService from "@/services/patient.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    value: {
      default: true,
    },
    patient: {},
    owner: {},
  },
  data() {
    return {
      internalOwner: {
        appointments: 0,
        sessions: 0,
      },
      cardLoading: false,
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    PatientCard: () => import("@/components/PatientCard"),
    RoundInitialsButton: () =>
      import("@/components/buttons/RoundInitialsButton"),
    DefaultTitle: () => import("@/components/text/DefaultTitle"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    Message: () => import("@/components/Message"),
    MoreCardActionButton: () =>
      import("@/components/buttons/MoreCardActionButton"),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    title() {
      if (this.patient) {
        return `Historia leczenia ${this.patientName}`;
      }
      return "Historia leczenia";
    },
    patientName() {
      if (this.patient.name) {
        return this.patient.name;
      } else {
        return `${this.patient.first_name} ${this.patient.last_name}`;
      }
    },
  },
  methods: {
    fetch() {
      this.beforeRequest();
      PatientService.getSessionInfo(
        this.patient ? this.patient.id : undefined,
        this.owner ? this.owner.id : undefined
      )
        .then((reply) => {
          this.internalOwner = reply;
          this.reset();
        })
        .catch((error) => {
          console.log("error:", error);
          this.handleError("Coś poszło nie tak.");
        });
    },
    getSession() {
      return this.$refs.pcard.activeTabSession;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style scoped lang="scss">
.popup-content {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
</style>